import { takeEvery } from 'redux-saga/effects';

import createAsyncAction from '../utils/createAsyncAction';
import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';

export const postLogin = createAsyncAction(actions.POST_LOGIN, handlers.postLoginHandler);
export const postRecovery = createAsyncAction(actions.POST_RECOVERY, handlers.postRecoveryHandler);
export const postReset = createAsyncAction(actions.POST_RESET, handlers.postResetHandler);

export const getUser = createAsyncAction(actions.GET_USER, handlers.getUserHandler);

export const getCompanies = createAsyncAction(actions.GET_COMPANIES, handlers.getCompaniesHandler);
export const postCompany = createAsyncAction(actions.POST_COMPANY, handlers.postCompanyHandler);
export const putCompany = createAsyncAction(actions.PUT_COMPANY, handlers.putCompanyHandler);
export const deleteCompany = createAsyncAction(actions.DELETE_COMPANY, handlers.deleteCompanyHandler);
export const getCompany = createAsyncAction(actions.GET_COMPANY, handlers.getCompanyHandler);

export const getParticipants = createAsyncAction(actions.GET_PARTICIPANTS, handlers.getParticipantsHandler);
export const getParticipant = createAsyncAction(actions.GET_PARTICIPANT, handlers.getParticipantHandler);
export const getParticipantLimit = createAsyncAction(actions.GET_PARTICIPANT_LIMIT, handlers.getParticipantLimitHandler);
export const postParticipant = createAsyncAction(actions.POST_PARTICIPANT, handlers.postParticipantHandler);
export const putParticipant = createAsyncAction(actions.PUT_PARTICIPANT, handlers.putParticipantHandler);
export const deleteParticipant = createAsyncAction(actions.DELETE_PARTICIPANT, handlers.deleteParticipantHandler);
export const getParticipationRequests = createAsyncAction(actions.GET_PARTICIPATION_REQUESTS, handlers.getParticipationRequestsHandler);
export const putParticipationRequests = createAsyncAction(actions.PUT_PARTICIPATION_REQUESTS, handlers.putParticipationRequestsHandler);
export const getParticipantDocuments = createAsyncAction(actions.GET_PARTICIPANT_DOCUMENTS, handlers.getParticipantDocumentsHandler);
export const postParticipantDocuments = createAsyncAction(actions.POST_PARTICIPANT_DOCUMENTS, handlers.postParticipantDocumentsHandler);
export const deleteParticipantDocuments = createAsyncAction(actions.DELETE_PARTICIPANT_DOCUMENTS, handlers.deleteParticipantDocumentsHandler);

export const deleteFile = createAsyncAction(actions.DELETE_FILE, handlers.deleteFileHandler);

export const getAdmins = createAsyncAction(actions.GET_ADMINS, handlers.getAdminsHandler, {}, takeEvery);
export const getAdmin = createAsyncAction(actions.GET_ADMIN, handlers.getAdminHandler);
export const postAdmin = createAsyncAction(actions.POST_ADMIN, handlers.postAdminHandler);
export const putAdmin = createAsyncAction(actions.PUT_ADMIN, handlers.putAdminHandler);
export const patchAdmin = createAsyncAction(actions.PATCH_ADMIN, handlers.patchAdminHandler);

export const getAdminClients = createAsyncAction(actions.GET_ADMIN_CLIENTS, handlers.getAdminClientsHandler);
export const getAdminClient = createAsyncAction(actions.GET_ADMIN_CLIENT, handlers.getAdminClientHandler);
export const postAdminClient = createAsyncAction(actions.POST_ADMIN_CLIENT, handlers.postAdminClientHandler);
export const putAdminClient = createAsyncAction(actions.PUT_ADMIN_CLIENT, handlers.putAdminClientHandler);
export const patchAdminClient = createAsyncAction(actions.PATCH_ADMIN_CLIENT, handlers.patchAdminClientHandler);

export const getStages = createAsyncAction(actions.GET_STAGES, handlers.getStagesHandler, []);
export const putStage = createAsyncAction(actions.PUT_STAGE, handlers.putStageHandler);
export const getStage = createAsyncAction(actions.GET_STAGE, handlers.getStageHandler, {}, takeEvery);
export const patchStage = createAsyncAction(actions.PATCH_STAGE, handlers.patchStageHandler);
export const postStage = createAsyncAction(actions.POST_STAGE, handlers.postStageHandler);
export const deleteStage = createAsyncAction(actions.DELETE_STAGE, handlers.deleteStageHandler);

export const putMaterial = createAsyncAction(actions.PUT_MATERIAL, handlers.putMaterialHandler);
export const getMaterial = createAsyncAction(actions.GET_MATERIAL, handlers.getMaterialHandler);

export const getMood = createAsyncAction(actions.GET_MOOD, handlers.getMoodHandler);

export const getFAQ = createAsyncAction(actions.GET_FAQ, handlers.getFAQHandler);
export const postFAQ = createAsyncAction(actions.POST_FAQ, handlers.postFAQHandler);
export const putFAQ = createAsyncAction(actions.PUT_FAQ, handlers.putFAQHandler);

export const getParticipantStages = createAsyncAction(actions.GET_PARTICIPANT_STAGES, handlers.getParticipantStagesHandler, []);
export const getParticipantMaterial = createAsyncAction(actions.GET_PARTICIPANT_MATERIAL, handlers.getParticipantMaterialHandler);
export const postParticipantsFile = createAsyncAction(actions.POST_PARTICIPANT_FILE, handlers.postParticipantFileHandler);

export const getInvites = createAsyncAction(actions.GET_INVITES, handlers.getInvitesHandler);
export const postInvites = createAsyncAction(actions.POST_INVITES, handlers.postInvitesHandler);

export const getTopics = createAsyncAction(actions.GET_TOPICS, handlers.getTopicsHandler);
export const postTopic = createAsyncAction(actions.POST_TOPIC, handlers.postTopicHandler);
export const putTopic = createAsyncAction(actions.PUT_TOPIC, handlers.putTopicHandler);
export const deleteTopic = createAsyncAction(actions.DELETE_TOPIC, handlers.deleteTopicHandler);

export const getSettings = createAsyncAction(actions.GET_SETTINGS, handlers.getSettingsHandler);
export const putSettingsPath = createAsyncAction(actions.PUT_SETTINGS_PATH, handlers.putSettingsPathHandler);
export const getSettingsPath = createAsyncAction(actions.GET_SETTINGS_PATH, handlers.getSettingsPathHandler);

export const getMentions = createAsyncAction(actions.GET_MENTIONS, handlers.getMentionsHandler);
export const postMention = createAsyncAction(actions.POST_MENTION, handlers.postMentionHandler);
export const putMention = createAsyncAction(actions.PUT_MENTION, handlers.putMentionHandler);
export const deleteMention = createAsyncAction(actions.DELETE_MENTION, handlers.deleteMentionHandler);

export const getNews = createAsyncAction(actions.GET_NEWS, handlers.getNewsHandler);
export const postNews = createAsyncAction(actions.POST_NEWS, handlers.postNewsHandler);
export const putNews = createAsyncAction(actions.PUT_NEWS, handlers.putNewsHandler);
export const deleteNews = createAsyncAction(actions.DELETE_NEWS, handlers.deleteNewsHandler);

export const getMaterialsStatistics = createAsyncAction(actions.GET_MATERIALS_STATISTICS, handlers.getMaterialsStatisticsHandler);

export const getFeedbackResults = createAsyncAction(actions.GET_FEEDBACK_RESULTS, handlers.getFeedbackResultsHandler);
export const getFeedbackStatistics = createAsyncAction(actions.GET_FEEDBACK_STATISTICS, handlers.getFeedbackStatisticsHandler);

export const getIndustryDictionary = createAsyncAction(actions.GET_INDUSTRY_DICTIONARY, handlers.getIndustryDictionaryHandler);
export const getFunctionsDictionary = createAsyncAction(actions.GET_FUNCTIONS_DICTIONARY, handlers.getFunctionsDictionaryHandler);

export const getAdminModules = createAsyncAction(actions.GET_ADMIN_MODULES, handlers.getAdminModulesHandler, [], takeEvery);
export const getCompanySettings = createAsyncAction(actions.GET_COMPANY_SETTINGS, handlers.getCompanySettingsHandler);
export const putCompanySettings = createAsyncAction(actions.PUT_COMPANY_SETTINGS, handlers.putCompanySettingsHandler);

export const getCompanyRoles = createAsyncAction(actions.GET_COMPANY_ROLES, handlers.getCompanyRolesHandler);
export const postCompanyRoles = createAsyncAction(actions.POST_COMPANY_ROLES, handlers.postCompanyRolesHandler);
export const putCompanyRoles = createAsyncAction(actions.PUT_COMPANY_ROLES, handlers.putCompanyRolesHandler);
export const deleteCompanyRoles = createAsyncAction(actions.DELETE_COMPANY_ROLES, handlers.deleteCompanyRolesHandler);

export const postInvitesImport = createAsyncAction(actions.POST_INVITES_IMPORT, handlers.postInvitesImportHandler);

export const getEventThemes = createAsyncAction(actions.GET_EVENT_THEMES, handlers.getEventThemesHandler);
export const postEventThemes = createAsyncAction(actions.POST_EVENT_THEMES, handlers.postEventThemesHandler);
export const putEventThemes = createAsyncAction(actions.PUT_EVENT_THEMES, handlers.putEventThemesHandler);
export const deleteEventThemes = createAsyncAction(actions.DELETE_EVENT_THEMES, handlers.deleteEventThemesHandler);

export const getEvents = createAsyncAction(actions.GET_EVENTS, handlers.getEventsHandler);
export const getEvent = createAsyncAction(actions.GET_EVENT, handlers.getEventHandler);
export const postEvent = createAsyncAction(actions.POST_EVENT, handlers.postEventHandler);
export const putEvent = createAsyncAction(actions.PUT_EVENT, handlers.putEventHandler);
export const deleteEvent = createAsyncAction(actions.DELETE_EVENT, handlers.deleteEventHandler);

export const getEventParticpation = createAsyncAction(actions.GET_EVENT_PARTICIPATION, handlers.getEventParticpationHandler, {}, takeEvery);
export const deleteEventParticipation = createAsyncAction(actions.DELETE_EVENT_PARTICIPATION, handlers.deleteEventParticipationHandler);
export const postEventParticipation = createAsyncAction(actions.POST_EVENT_PARTICIPATION, handlers.postEventParticipationHandler);
export const putEventParticipationAttend = createAsyncAction(actions.PUT_EVENT_PARTICIPATION_ATTEND, handlers.putEventParticipationAttendHandler);
export const putEventParticipationAttendCancel = createAsyncAction(actions.PUT_EVENT_PARTICIPATION_ATTEND_CANCEL, handlers.putEventParticipationAttendCancelHandler);

export const getTestThemes = createAsyncAction(actions.GET_TEST_THEMES, handlers.getTestThemesHandler);
export const postTestThemes = createAsyncAction(actions.POST_TEST_THEMES, handlers.postTestThemesHandler);
export const putTestThemes = createAsyncAction(actions.PUT_TEST_THEMES, handlers.putTestThemesHandler);
export const deleteTestThemes = createAsyncAction(actions.DELETE_TEST_THEMES, handlers.deleteTestThemesHandler);

export const putDepersonalize = createAsyncAction(actions.PUT_DEPERSONALIZE, handlers.putDepersonalizeHandler);
export const getParticipantStatistic = createAsyncAction(actions.GET_PARTICIPANT_STATISTIC, handlers.getParticipantStatisticHandler);

export const getParticipantStatuses = createAsyncAction(actions.GET_PARTICIPANT_STATUSES, handlers.getParticipantStatusesHandler);
export const getParticipantStatus = createAsyncAction(actions.GET_PARTICIPANT_STATUS, handlers.getParticipantStatusHandler);
export const postParticipantStatuses = createAsyncAction(actions.POST_PARTICIPANT_STATUSES, handlers.postParticipantStatusesHandler);
export const putParticipantStatuses = createAsyncAction(actions.PUT_PARTICIPANT_STATUSES, handlers.putParticipantStatusesHandler);
export const deleteParticipantStatuses = createAsyncAction(actions.DELETE_PARTICIPANT_STATUSES, handlers.deleteParticipantStatusesHandler);

export const getPassword = createAsyncAction(actions.GET_PASSWORD, handlers.getPasswordHandler);
export const postPassword = createAsyncAction(actions.POST_PASSWORD, handlers.postPasswordHandler);

export const putResendEmail = createAsyncAction(actions.PUT_RESEND_EMAIL, handlers.putResendEmailHandler, {}, takeEvery);
export const putResendEmailParticipant = createAsyncAction(actions.PUT_RESEND_EMAIL_PARTICIPANT, handlers.putResendEmailParticipantHandler, {}, takeEvery);

export const getParticipantContentStages = createAsyncAction(actions.GET_PARTICIPANT_CONTENT_STAGES, handlers.getParticipantContentStagesHandler);

export const getConsultationDurations = createAsyncAction(actions.GET_CONSULTATION_DURATIONS, handlers.getConsultationDurationsHandler, []);

export const putSetConsultant = createAsyncAction(actions.PUT_SET_CONSULTANT, handlers.putSetConsultantHandler);

export const getPromotionDurations = createAsyncAction(actions.GET_PROMOTION_DURATIONS, handlers.getPromotionDurationsHandler, []);
export const postPromotions = createAsyncAction(actions.POST_PROMOTIONS, handlers.postPromotionsHandler);
export const putPromotions = createAsyncAction(actions.PUT_PROMOTIONS, handlers.putPromotionsHandler);

export const getPromotionServiceTypes = createAsyncAction(actions.GET_PROMOTION_SERVICE_TYPES, handlers.getPromotionServiceTypesHandler, []);

export const getPromotionRequests = createAsyncAction(actions.GET_PROMOTION_REQUESTS, handlers.getPromotionRequestsHandler);
export const getPromotionRequest = createAsyncAction(actions.GET_PROMOTION_REQUEST, handlers.getPromotionRequestHandler);
export const postPromotionRequests = createAsyncAction(actions.POST_PROMOTION_REQUESTS, handlers.postPromotionRequestsHandler);
export const getPromotionRequestsStatuses = createAsyncAction(actions.GET_PROMOTION_REQUESTS_STATUSES, handlers.getPromotionRequestsStatusesHandler, []);
export const getPromotionRequestsThemes = createAsyncAction(actions.GET_PROMOTION_REQUESTS_THEMES, handlers.getPromotionRequestsThemesHandler, {}, takeEvery);

export const getConsultations = createAsyncAction(actions.GET_CONSULTATIONS, handlers.getConsultationsHandler);
export const getConsultation = createAsyncAction(actions.GET_CONSULTATION, handlers.getConsultationHandler);
export const putConsultationTitle = createAsyncAction(actions.PUT_CONSULTATION_TITLE, handlers.putConsultationTitleHandler);

export const postCalcom = createAsyncAction(actions.POST_CALCOM, handlers.postCalcomHandler, {}, takeEvery);

export const getParticipantsHistory = createAsyncAction(actions.GET_PARTICIPANTS_HISTORY, handlers.getParticipantsHistoryHandler);
export const getParticipantsList = createAsyncAction(actions.GET_PARTICIPANTS_LIST, handlers.getParticipantsListHandler, [], takeEvery);
export const getParticipantsSummary = createAsyncAction(actions.GET_PARTICIPANTS_SUMMARY, handlers.getParticipantsSummaryHandler);
export const putParticipantComment = createAsyncAction(actions.PUT_PARTICIPANT_COMMENT, handlers.putParticipantCommentHandler);
export const getParticipantsStatuses = createAsyncAction(actions.GET_PARTICIPANTS_STATUSES, handlers.getParticipantsStatusesHandler);

export const getParticipantFeedbacks = createAsyncAction(actions.GET_PARTICIPANT_FEEDBACKS, handlers.getParticipantFeedbacksHandler);
export const postParticipantFeedbackEvent = createAsyncAction(actions.POST_PARTICIPANT_FEEDBACK_EVENT, handlers.postParticipantFeedbackEventHandler);
export const postParticipantFeedbackConsultation = createAsyncAction(actions.POST_PARTICIPANT_FEEDBACK_CONSULTATION, handlers.postParticipantFeedbackConsultationHandler);
export const putParticipantFeedback = createAsyncAction(actions.PUT_PARTICIPANT_FEEDBACK, handlers.putParticipantFeedbackHandler);
export const deleteParticipantFeedback = createAsyncAction(actions.DELETE_PARTICIPANT_FEEDBACK, handlers.deleteParticipantFeedbackHandler);

export const getCompanyBilling = createAsyncAction(actions.GET_COMPANY_BILLING, handlers.getCompanyBillingHandler);

export const getCompanyStatisticEvents = createAsyncAction(actions.GET_COMPANY_STATISTIC_EVENTS, handlers.getCompanyStatisticEventsHandler);
export const getCompanyStatisticEventsAverageAttendance = createAsyncAction(actions.GET_COMPANY_STATISTIC_EVENTS_AVERAGE_ATTENDANCE, handlers.getCompanyStatisticEventsAverageAttendanceHandler);
export const getCompanyStatisticConsultations = createAsyncAction(actions.GET_COMPANY_STATISTIC_CONSULTATIONS, handlers.getCompanyStatisticConsultationsHandler);
export const getCompanyStatisticContent = createAsyncAction(actions.GET_COMPANY_STATISTIC_CONTENT, handlers.getCompanyStatisticContentHandler);
export const getCompanyStatisticPromotion = createAsyncAction(actions.GET_COMPANY_STATISTIC_PROMOTION, handlers.getCompanyStatisticPromotionHandler);
export const getCompanyStatisticEventsThemes = createAsyncAction(actions.GET_COMPANY_STATISTIC_EVENTS_THEMES, handlers.getCompanyStatisticEventsThemesHandler);
export const getCompanyStatisticEventsSpeakers = createAsyncAction(actions.GET_COMPANY_STATISTIC_EVENTS_SPEAKERS, handlers.getCompanyStatisticEventsSpeakersHandler);
export const getCompanyStatisticPromotionRoles = createAsyncAction(actions.GET_COMPANY_STATISTIC_PROMOTION_ROLES, handlers.getCompanyStatisticPromotionRolesHandler);
export const getCompanyStatisticConsultationsRoles = createAsyncAction(actions.GET_COMPANY_STATISTIC_CONSULTATIONS_ROLES, handlers.getCompanyStatisticConsultationsRolesHandler);
export const getCompanyStatisticPromotionMonths = createAsyncAction(actions.GET_COMPANY_STATISTIC_PROMOTION_MONTHS, handlers.getCompanyStatisticPromotionMonthsHandler);
export const getCompanyStatisticPromotionConsultant = createAsyncAction(actions.GET_COMPANY_STATISTIC_PROMOTION_CONSULTANT, handlers.getCompanyStatisticPromotionConsultantHandler);
export const getCompanyStatisticParticipants = createAsyncAction(actions.GET_COMPANY_STATISTIC_PARTICIPANTS, handlers.getCompanyStatisticParticipantsHandler);
export const getCompanyStatisticStatuses = createAsyncAction(actions.GET_COMPANY_STATISTIC_STATUSES, handlers.getCompanyStatisticStatusesHandler);
export const getCompanyStatisticParticipantsAverageLifetime = createAsyncAction(actions.GET_COMPANY_STATISTIC_PARTICIPANTS_AVERAGE_LIFETIME, handlers.getCompanyStatisticParticipantsAverageLifetimeHandler);
export const getCompanyStatisticDyncamicUsage = createAsyncAction(actions.GET_COMPANY_STATISTIC_DYNAMIC_USAGE, handlers.getCompanyStatisticDyncamicUsageHandler);
export const getCompanyStatisticConsultants = createAsyncAction(actions.GET_COMPANY_STATISTIC_CONSULTANTS, handlers.getCompanyStatisticConsultantsHandler);

export const getEmployeeLinks = createAsyncAction(actions.GET_EMPLOYEE_LINKS, handlers.getEmployeeLinksHandler);
export const getEmployeeLink = createAsyncAction(actions.GET_EMPLOYEE_LINK, handlers.getEmployeeLinkHandler);
export const postEmployeetLink = createAsyncAction(actions.POST_EMPLOYEE_LINK, handlers.postEmployeeLinkHandler);
export const putEmployeeLink = createAsyncAction(actions.PUT_EMPLOYEE_LINK, handlers.putEmployeeLinkHandler);
export const deleteEmployeeLink = createAsyncAction(actions.DELETE_EMPLOYEE_LINK, handlers.deleteEmployeeLinkHandler);
export const getEmployeeList = createAsyncAction(actions.GET_EMPLOYEE_LIST, handlers.getEmployeeListHandler);
export const getEmployeeRequest = createAsyncAction(actions.GET_EMPLOYEE_REQUEST, handlers.getEmployeeRequestHandler);

export const getMessageTemplates = createAsyncAction(actions.GET_MESSAGE_TEMPLATES, handlers.getMessageTemplatesHandler);
export const getMessageTemplate = createAsyncAction(actions.GET_MESSAGE_TEMPLATE, handlers.getMessageTemplateHandler);
export const postMessageTemplate = createAsyncAction(actions.POST_MESSAGE_TEMPLATE, handlers.postMessageTemplateHandler);
export const putMessageTemplate = createAsyncAction(actions.PUT_MESSAGE_TEMPLATE, handlers.putMessageTemplateHandler);
