export const POST_LOGIN = 'POST_LOGIN';
export const LOGOUT = 'LOGOUT';

export const GET_USER = 'GET_USER';

export const POST_REFRESH_TOKEN = 'POST_REFRESH_TOKEN';
export const POST_RECOVERY = 'POST_RECOVERY';
export const POST_RESET = 'POST_RESET';

export const GET_COMPANIES = 'GET_COMPANIES';
export const POST_COMPANY = 'POST_COMPANY';
export const PUT_COMPANY = 'PUT_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

export const OPEN_COMPANY_MODAL = 'OPEN_COMPANY_MODAL';
export const OPEN_PARTICIPANT_MODAL = 'OPEN_PARTICIPANT_MODAL';
export const OPEN_PARTICIPANT_IMPORT_MODAL = 'OPEN_PARTICIPANT_IMPORT_MODAL';
export const OPEN_PARTICIPATION_REQUEST_MODAL = 'OPEN_PARTICIPATION_REQUEST_MODAL';
export const OPEN_ADMIN_MODAL = 'OPEN_ADMIN_MODAL';
export const OPEN_STAGE_MODAL = 'OPEN_STAGE_MODAL';
export const OPEN_MATERIAL_MODAL = 'OPEN_MATERIAL_MODAL';
export const OPEN_CANDIDATE_FORM_MODAL = 'OPEN_CANDIDATE_FORM_MODAL';
export const OPEN_FAQ_MODAL = 'OPEN_FAQ_MODAL';
export const OPEN_MATERIAL_VIEW_MODAL = 'OPEN_MATERIAL_VIEW_MODAL';
export const OPEN_TOPIC_MODAL = 'OPEN_TOPIC_MODAL';
export const OPEN_MENTION_MODAL = 'OPEN_MENTION_MODAL';
export const OPEN_NEWS_MODAL = 'OPEN_NEWS_MODAL';
export const OPEN_COMPANY_SETTINGS_MODAL = 'OPEN_COMPANY_SETTINGS_MODAL';
export const OPEN_ADMIN_CLIENT_MODAL = 'OPEN_ADMIN_CLIENT_MODAL';
export const OPEN_COMPANY_ROLES_MODAL = 'OPEN_COMPANY_ROLES_MODAL';
export const OPEN_IMPORT_INVITES_MODAL = 'OPEN_IMPORT_INVITES_MODAL';
export const OPEN_INVITE_MODAL = 'OPEN_INVITE_MODAL';
export const OPEN_WEBINAR_MODAL = 'OPEN_WEBINAR_MODAL';
export const OPEN_TEST_MODAL = 'OPEN_TEST_MODAL';
export const OPEN_COMPANY_WEBINAR_MODAL = 'OPEN_COMPANY_WEBINAR_MODAL';
export const OPEN_EVENT_PARTICIPANTS_MODAL = 'OPEN_PARTICIPANTS_MODAL';
export const OPEN_PARTICIPANT_STATUS_MODAL = 'OPEN_PARTICIPNAT_STATUS_MODAL';
export const OPEN_EVENT_PARTICIPANTS_ADD_MODAL = 'OPEN_EVENT_PARTICIPANTS_ADD_MODAL';
export const OPEN_CONSULTANT_SELECT_MODAL = 'OPEN_CONSULTANT_SELECT_MODAL';
export const OPEN_CONSULTATION_VIEW_MODAL = 'OPEN_CONSULTATION_VIEW_MODAL';
export const OPEN_CONSULTATION_TITLE_MODAL = 'OPEN_CONSULTATION_TITLE_MODAL';
export const OPEN_EVENT_VIEW_MODAL = 'OPEN_EVENT_VIEW_MODAL';
export const OPEN_ADD_MEETING_MODAL = 'OPEN_ADD_MEETING_MODAL';
export const OPEN_CANCEL_CONSULTATION_MODAL = 'OPEN_CANCEL_CONSULTATION_MODAL';
export const OPEN_STATUS_COMMENT_MODAL = 'OPEN_STATUS_COMMENT_MODAL';
export const OPEN_PARTICIPANT_COMMENT_MODAL = 'OPEN_PARTICIPANT_COMMENT_MODAL';
export const OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL = 'OPEN_PARTICIPANTS_TABLE_SETTINGS_MODAL';
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const OPEN_PARTICIPANT_FEEDBACK_MODAL = 'OPEN_PARTICIPANT_FEEDBACK_MODAL';
export const OPEN_PARTICIPANT_DOCUMENT_MODAL = 'OPEN_PARTICIPANT_DOCUMENT_MODAL';
export const OPEN_LINK_MODAL = 'OPEN_LINK_MODAL';
export const OPEN_LINK_PREVIEW_MODAL = 'OPEN_LINK_PREVIEW_MODAL';
export const OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL = 'OPEN_COMPANY_MESSAGE_TEMPLATE_MODAL';

export const GET_PARTICIPANTS = 'GET_PARTICIPANTS';
export const GET_PARTICIPANT = 'GET_PARTICIPANT';
export const GET_PARTICIPANT_LIMIT = 'GET_PARTICIPANT_LIMIT';
export const POST_PARTICIPANT = 'POST_PARTICIPANT';
export const POST_PARTICIPANT_FILE = 'POST_PARTICIPANT_FILE';
export const PUT_PARTICIPANT = 'PUT_PARTICIPANT';
export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';
export const GET_PARTICIPANT_DOCUMENTS = 'GET_PARTICIPANT_DOCUMENTS';
export const POST_PARTICIPANT_DOCUMENTS = 'POST_PARTICIPANT_DOCUMENTS';
export const DELETE_PARTICIPANT_DOCUMENTS = 'DELETE_PARTICIPANT_DOCUMENTS';

export const GET_PARTICIPATION_REQUESTS = 'GET_PARTICIPATION_REQUESTS';
export const PUT_PARTICIPATION_REQUESTS = 'PUT_PARTICIPATION_REQUESTS';

export const DELETE_FILE = 'DELETE_FILE';

export const GET_ADMINS = 'GET_ADMINS';
export const GET_ADMIN = 'GET_ADMIN';
export const POST_ADMIN = 'POST_ADMIN';
export const PUT_ADMIN = 'PUT_ADMIN';
export const PATCH_ADMIN = 'PATCH_ADMIN';

export const GET_ADMIN_CLIENTS = 'GET_ADMIN_CLIENTS';
export const GET_ADMIN_CLIENT = 'GET_ADMIN_CLIENT';
export const POST_ADMIN_CLIENT = 'POST_ADMIN_CLIENT';
export const PUT_ADMIN_CLIENT = 'PUT_ADMIN_CLIENT';
export const PATCH_ADMIN_CLIENT = 'PATCH_ADMIN_CLIENT';

export const GET_STAGES = 'GET_STAGES';
export const PUT_STAGE = 'PUT_STAGE';
export const GET_STAGE = 'GET_STAGE';
export const PATCH_STAGE = 'PATCH_STAGE';
export const POST_STAGE = 'POST_STAGE';
export const DELETE_STAGE = 'DELETE_STAGE';

export const PUT_MATERIAL = 'PUT_MATERIAL';
export const GET_MATERIAL = 'GET_MATERIAL';

export const GET_MOOD = 'GET_MOOD';

export const GET_FAQ = 'GET_FAQ';
export const POST_FAQ = 'POST_FAQ';
export const PUT_FAQ = 'PUT_FAQ';

export const GET_PARTICIPANT_STAGES = 'GET_PARTICIPANT_STAGES';
export const GET_PARTICIPANT_MATERIAL = 'GET_PARTICIPANT_MATERIAL';

export const GET_INVITES = 'GET_INVITES';
export const POST_INVITES = 'POST_INVITES';

export const GET_TOPICS = 'GET_TOPICS';
export const POST_TOPIC = 'POST_TOPIC';
export const PUT_TOPIC = 'PUT_TOPIC';
export const DELETE_TOPIC = 'DELETE_TOPIC';

export const GET_SETTINGS = 'GET_SETTINGS';
export const PUT_SETTINGS_PATH = 'PUT_SETTINGS_PATH';
export const GET_SETTINGS_PATH = 'GET_SETTINGS_PATH';

export const GET_MENTIONS = 'GET_MENTIONS';
export const POST_MENTION = 'POST_MENTION';
export const PUT_MENTION = 'PUT_MENTION';
export const DELETE_MENTION = 'DELETE_MENTION';

export const GET_NEWS = 'GET_NEWS';
export const POST_NEWS = 'POST_NEWS';
export const PUT_NEWS = 'PUT_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';

export const GET_MATERIALS_STATISTICS = 'GET_MATERIALS_STATISTICS';

export const GET_FEEDBACK_RESULTS = 'GET_FEEDBACK_RESULTS';
export const GET_FEEDBACK_STATISTICS = 'GET_FEEDBACK_STATISTICS';
export const GET_INDUSTRY_DICTIONARY = 'GET_INDUSTRY_DICTIONARY';
export const GET_FUNCTIONS_DICTIONARY = 'GET_FUNCTIONS_DICTIONARY';

export const GET_ADMIN_MODULES = 'GET_ADMIN_MODULES';

export const GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS';
export const PUT_COMPANY_SETTINGS = 'PUT_COMPANY_SETTINGS';
export const GET_COMPANY_ROLES = 'GET_COMPANY_ROLES';
export const POST_COMPANY_ROLES = 'POST_COMPANY_ROLES';
export const PUT_COMPANY_ROLES = 'PUT_COMPANY_ROLES';
export const DELETE_COMPANY_ROLES = 'DELETE_COMPANY_ROLES';

export const POST_INVITES_IMPORT = 'POST_INVITES_IMPORT';

export const GET_EVENT_THEMES = 'GET_EVENT_THEMES';
export const POST_EVENT_THEMES = 'POST_EVENT_THEMES';
export const PUT_EVENT_THEMES = 'PUT_EVENT_THEMES';
export const DELETE_EVENT_THEMES = 'DELETE_EVENT_THEMES';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const POST_EVENT = 'POST_EVENT';
export const PUT_EVENT = 'PUT_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';

export const GET_EVENT_PARTICIPATION = 'GET_EVENT_PARTICIPATION';
export const DELETE_EVENT_PARTICIPATION = 'DELETE_EVENT_PARTICIPATION';
export const POST_EVENT_PARTICIPATION = 'POST_EVENT_PARTICIPATION';
export const PUT_EVENT_PARTICIPATION_ATTEND = 'PUT_EVENT_PARTICIPATION_ATTEND';
export const PUT_EVENT_PARTICIPATION_ATTEND_CANCEL = 'PUT_EVENT_PARTICIPATION_ATTEND_CANCEL';

export const GET_TEST_THEMES = 'GET_TEST_THEMES';
export const POST_TEST_THEMES = 'POST_TEST_THEMES';
export const PUT_TEST_THEMES = 'PUT_TEST_THEMES';
export const DELETE_TEST_THEMES = 'DELETE_TEST_THEMES';

export const PUT_DEPERSONALIZE = 'PUT_DEPERSONALIZE';
export const GET_PARTICIPANT_STATISTIC = 'GET_PARTICIPANT_STATISTIC';

export const GET_PARTICIPANT_STATUSES = 'GET_PARTICIPANT_STATUSES';
export const GET_PARTICIPANT_STATUS = 'GET_PARTICIPANT_STATUS';
export const POST_PARTICIPANT_STATUSES = 'POST_PARTICIPANT_STATUSES';
export const PUT_PARTICIPANT_STATUSES = 'PUT_PARTICIPANT_STATUSES';
export const DELETE_PARTICIPANT_STATUSES = 'DELETE_PARTICIPANT_STATUSES';

export const GET_PASSWORD = 'GET_PASSWORD';
export const POST_PASSWORD = 'POST_PASSWORD';

export const PUT_RESEND_EMAIL = 'PUT_RESEND_EMAIL';
export const PUT_RESEND_EMAIL_PARTICIPANT = 'PUT_RESEND_EMAIL_PARTICIPANT';
export const GET_PARTICIPANT_CONTENT_STAGES = 'GET_PARTICIPANT_CONTENT_STAGES';

export const GET_CONSULTATION_DURATIONS = 'GET_CONSULTATION_DURATIONS';

export const PUT_SET_CONSULTANT = 'PUT_SET_CONSULTANT';

export const GET_PROMOTION_DURATIONS = 'GET_PROMOTION_DURATIONS';
export const POST_PROMOTIONS = 'POST_PROMOTIONS';
export const PUT_PROMOTIONS = 'PUT_PROMOTIONS';

export const GET_PROMOTION_SERVICE_TYPES = 'GET_PROMOTION_SERVICE_TYPES';
export const GET_PROMOTION_REQUESTS = 'GET_PROMOTION_REQUESTS';
export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST';
export const POST_PROMOTION_REQUESTS = 'POST_PROMOTION_REQUESTS';
export const GET_PROMOTION_REQUESTS_STATUSES = 'GET_PROMOTION_REQUESTS_STATUSES';
export const GET_PROMOTION_REQUESTS_THEMES = 'GET_PROMOTION_REQUESTS_THEMES';

export const GET_CONSULTATIONS = 'GET_CONSULTATIONS';
export const GET_CONSULTATION = 'GET_CONSULTATION';
export const PUT_CONSULTATION_TITLE = 'PUT_CONSULTATION_TITLE';

export const POST_CALCOM = 'POST_CALCOM';

export const GET_PARTICIPANTS_HISTORY = 'GET_PARTICIPANTS_HISTORY';
export const GET_PARTICIPANTS_SUMMARY = 'GET_PARTICIPANTS_SUMMARY';
export const GET_PARTICIPANTS_LIST = 'GET_PARTICIPANTS_LIST';

export const PUT_PARTICIPANT_COMMENT = 'PUT_PARTICIPANT_COMMENT';
export const GET_PARTICIPANTS_STATUSES = 'GET_PARTICIPANTS_STATUSES';

export const GET_PARTICIPANT_FEEDBACKS = 'GET_PARTICIPANT_FEEDBACKS';
export const POST_PARTICIPANT_FEEDBACK_EVENT = 'POST_PARTICIPANT_FEEDBACK_EVENT';
export const POST_PARTICIPANT_FEEDBACK_CONSULTATION = 'POST_PARTICIPANT_FEEDBACK_CONSULTATION';
export const PUT_PARTICIPANT_FEEDBACK = 'PUT_PARTICIPANT_FEEDBACK';
export const DELETE_PARTICIPANT_FEEDBACK = 'DELETE_PARTICIPANT_FEEDBACK';

export const GET_COMPANY_BILLING = 'GET_COMPANY_BILLING';

export const GET_COMPANY_STATISTIC_EVENTS = 'COMPANY_STATISTIC_EVENTS';
export const GET_COMPANY_STATISTIC_EVENTS_AVERAGE_ATTENDANCE = 'GET_COMPANY_STATISTIC_EVENTS_AVERAGE_ATTENDANCE';
export const GET_COMPANY_STATISTIC_CONSULTATIONS = 'GET_COMPANY_STATISTIC_CONSULTATIONS';
export const GET_COMPANY_STATISTIC_CONTENT = 'GET_COMPANY_STATISTIC_CONTENT';
export const GET_COMPANY_STATISTIC_PROMOTION = 'GET_COMPANY_STATISTIC_PROMOTION';
export const GET_COMPANY_STATISTIC_EVENTS_THEMES = 'GET_COMPANY_STATISTIC_EVENTS_THEMES';
export const GET_COMPANY_STATISTIC_EVENTS_SPEAKERS = 'GET_COMPANY_STATISTIC_EVENTS_SPEAKERS';
export const GET_COMPANY_STATISTIC_PROMOTION_ROLES = 'GET_COMPANY_STATISTIC_PROMOTION_ROLES';
export const GET_COMPANY_STATISTIC_CONSULTATIONS_ROLES = 'GET_COMPANY_STATISTIC_CONSULTATIONS_ROLES';
export const GET_COMPANY_STATISTIC_PROMOTION_MONTHS = 'GET_COMPANY_STATISTIC_PROMOTION_MONTHS';
export const GET_COMPANY_STATISTIC_PROMOTION_CONSULTANT = 'GET_COMPANY_STATISTIC_PROMOTION_CONSULTANT';
export const GET_COMPANY_STATISTIC_PARTICIPANTS = 'GET_COMPANY_STATISTIC_PARTICIPANTS';
export const GET_COMPANY_STATISTIC_STATUSES = 'GET_COMPANY_STATISTIC_STATUSES';
export const GET_COMPANY_STATISTIC_PARTICIPANTS_AVERAGE_LIFETIME = 'GET_COMPANY_STATISTIC_PARTICIPANTS_AVERAGE_LIFETIME';
export const GET_COMPANY_STATISTIC_DYNAMIC_USAGE = 'GET_COMPANY_STATISTIC_DYNAMIC_USAGE';
export const GET_COMPANY_STATISTIC_CONSULTANTS = 'GET_COMPANY_STATISTIC_CONSULTANTS';

export const GET_EMPLOYEE_LINKS = 'GET_EMPLOYEE_LINKS';
export const GET_EMPLOYEE_LINK = 'GET_EMPLOYEE_LINK';
export const POST_EMPLOYEE_LINK = 'POST_EMPLOYEE_LINK';
export const PUT_EMPLOYEE_LINK = 'PUT_EMPLOYEE_LINK';
export const DELETE_EMPLOYEE_LINK = 'DELETE_EMPLOYEE_LINK';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';

export const GET_MESSAGE_TEMPLATES = 'GET_MESSAGE_TEMPLATES';
export const GET_MESSAGE_TEMPLATE = 'GET_MESSAGE_TEMPLATE';
export const POST_MESSAGE_TEMPLATE = 'POST_MESSAGE_TEMPLATE';
export const PUT_MESSAGE_TEMPLATE = 'PUT_MESSAGE_TEMPLATE';
